import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="temp09_info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>温度計に関する一口メモ</h2>
					<div className="content-container">
						<div className="office_inner">
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title" id="absolute_temperature">
										【絶対温度】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【ウィーンの変位則】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										熱力学から生まれ、物質の特異性に依存しない温度目盛を定義したものです。
										<br />
										1848年、ケルビンによって導入され、0℃=273.15Kと定義されています。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										対温度Tと放射される赤外線のピーク波長（λmax）が反比例することを表わす公式です。
										<br />
										λmax・T=a（一定）
										<br />
										λmax：ピーク波長
										<br />
										a：ウィーンの変位定数2897.8μm・K
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p id="thermal_equilibrium" className="temp01_font_title">
										【熱平衡】<span className="furigana">ねつへいこう</span>
									</p>

								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【キルヒホッフの法則】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										温度差のあるふたつの物体を接触させると、物体の熱が熱い方から冷たい方へ移動し、やがて一定となります。
										<br />
										この状態を熱平衡といいます。一般の接触型温度計はこの原理を利用しています。
										<br />
										また、接触型温度計で熱容量の小さな物体を測定する時、温度計の影響によって測定物体の熱平衡状態が乱れ、温度が変化してしまう現象を
										【熱じょう乱】といいます。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										物体固有の赤外線放射率は、その物体の吸収率に等しいことを表わした法則です。
										<br />
										熱平衡状態においては、放射される赤外線と吸収される赤外線の量は一致します。
										<br />
										例えば下図のように熱平衡状態（同じ温度）の物体aとbがあります。aから放射される赤外線の一部が、bによって反射あるいは吸収されるものと仮定すれば、吸収されることによってbの温度が上昇することになります。
										<br />
										ところが、aとbは熱平衡状態ですからbだけの温度が上昇するのは不自然なことになります。
										<br />
										つまり、吸収した量と同じ量の赤外線をbが放出していると考えればいいわけです。この法則を確立したのがキルヒホッフです。
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title" id="stefan-boltzmann_law">
										【ステファン・ボルツマンの法則】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										絶対温度と全放射エネルギーの関係は、プランクの放射法則を全波長で積分したもので、絶対温度の4乗に比例します。
										<br />
										E=σT<sup>4 </sup>
										<br />
										E：全放射エネルギーW／m<sup>2 </sup>
										<br />
										σ：ステファン・ボルツマン定数
										<br />
										5.6697×10<sup>-8</sup> W／m<sup>2 </sup>／K<sup>4 </sup>
									</p>
								</li>

								<li className="temp10_explanation">
									<img
										alt="キルヒホッフの法則の図"
										className="temp10_image"
										height="190"
										width="250"
										src="/asset/m01.gif"
									/>
								</li>
							</ul>

							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title" id="wavelength_unit">
										【波長の単位】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【プランクの放射法則】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										波長の場合、通常λ（ラムダ）という記号が用いられ、単位としてμm（ミクロンメータ）またはnm（ナノメータ）が使われます。また、分子構造などに関係する赤外線の単位はcm-1（カイザー）が使われています。
										<br />
										1cm　=10<sup>-2 </sup>m　1mm　=10<sup>-3 </sup>m<br />
										1μm　=10<sup>-6 </sup>m （0.000001m）
										<br />
										1μm　=1000nm=10-3mm=10<sup>-4 </sup>cm
										<br />
										1nm　=10<sup>-9 </sup>m （0.000000001m）
										<br />
										1nm　=10<sup>-3 </sup>μm=10<sup>-6 </sup>mm=10<sup>-7 </sup>
										cm
										<br />
										1A（オングストローム）=10<sup>-10</sup>m（0.0000000001m）
										<br />
										1A（オングストローム）=10<sup>-4</sup>μm=10<sup>-8 </sup>cm
										<br />
										cm<sup>-1</sup>（カイザー）=波の個数／1cm
										<br />
										（1cmの中に入っている波の数）
										<br />
										例えば波長（λ）が1μmの場合、
										<br />
										1μm=10<sup>-6 </sup>m=10<sup>-4 </sup>cm
										<br />
										カイザーに変換すると1／10<sup>-4 </sup>cm=10<sup>4 </sup>cm<sup>-1</sup>になります。
										<br />
										したがって、1μmの場合、1cmの中に1万個の波が存在していることになります。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										温度と波長によって求められる、黒体が半球空間に放射する熱放射エネルギーの公式です。
										<br />
										E（λ・T）= C<sub>1</sub>／λ<sup>5</sup>・1／exp(C
										<sub>2</sub>／λT)-1
										<br />
										E（λ・T：放射エネルギー密度W・μm／m<sup>2</sup>
										<br />
										λ：波長μm
										<br />
										T：絶対温度K
										<br />C<sub>1</sub>：プランクの放射第一定数3.7415×10
										<sup>8</sup> W・μm<sup>4 </sup>／m<sup>2</sup>
										<br />C<sub>2</sub>：プランクの放射第二定数1.43879 μm・K
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<img
										alt="波長の単位の図"
										className="temp10_image2"
										height="96"
										width="190"
										src="/asset/m02.gif"
									/>
								</li>
								<li className="temp10_title">
									<img
										alt="プランクの放射法則の図"
										className="temp10_image2"
										height="96"
										width="150"
										src="/asset/m03.gif"
									/>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【センサー】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										赤外線センサーは大きく分けて「熱型」と「量子型」に分類できます。
										<br />
										熱型センサーは、赤外線を受けることにより温度が上昇します。
										<br />
										この温度上昇によって起こる焦電現象、熱起電力の変化などの電気的性質を電気信号に変換します。
										<br />
										この熱型センサーには、焦電素子、サーモパイルなどがあります。
										<br />
										また、量子型センサーは、赤外線を受けるとセンサーが直接励起され、電力的な性質に変化を生じます。
										<br />
										この変化量を電気信号に変換します。この量子型センサーには、シリコンセンサー、ゲルマニウムセンサーなどがあります。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										<br />
										<br />
										<br />
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【リニアライザー】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【温度ドリフト】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										直線的な比例関係であればすぐに値が求められますが、温度と放射赤外線の関係のように、複雑に変化するものは直線的な比例関係では簡単に求められません。
										<br />
										このようなときに比例関係に置き換えるメカニズムで、放射温度計に内蔵されているROMなどにプログラムされています。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										測定周囲（放射温度計周辺）の温度が変化することによって測定値が変化することをいいます。
										<br />
										通常、周囲の温度が１℃変化した時に指示値がどの程度変化するかを示す％K／℃や、％／℃の単位で表わします。この数値が小さいほど性能が優れています。
										<br />
										たとえば、温度ドリフトが±0.1％／℃の放射温度計で200℃の測定物体（放射率≒1）を測定している時に、周囲温度が30℃から10℃に変わったとすると
										<br />
										（200℃）×（±0.1％）×（30℃-10℃）=±4℃
										<br />
										となります。
										<br />
										したがって、この場合、196℃～204℃の範囲内におさまります。
										<br />
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【熱伝導率】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【応答時間】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										熱の伝わりやすさをいいます。
										<br />
										例えば、接触型温度計でゴム、プラスチックなどの、熱伝導率の小さな測定物体を測定しようとすると、
										<br />
										接触状態にあってもその熱が温度計に伝わりにくく、長い時間を要します。
										<br />
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										測定物体がステップ的に温度変化したとき、放射温度計がその温度を表示するまでの時間をいいます。
									</p>
								</li>
							</ul>

							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【分光放射輝度】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【精度定格】
									</p>
								</li>
							</ul>

							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										各波長点での放射エネルギーの単位面積、単位立体角あたりの強度を表わしたものです。
									</p>
								</li>

								<li className="temp10_explanation">
									<p className="medium">
										測定物体の絶対値（真の値）に対する側定値のズレ量で、この値が小さいほど性能が優れています。
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【黒体炉】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【標的サイズ】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									放射率がほとんど１に近い値を持つ炉で、放射温度計の校正を行なう時などに使われます。
								</li>
								<li className="temp10_explanation">
									放射温度計が測定している部分の大きさで、通常、ファインダーで確認できます。
								</li>
							</ul>

							<ul className="temp10_ul_04image">
								<li>
									<img
										alt="標準サイズの図"
										className="temp10_image"
										height="198"
										width="290"
										src="/asset/m04.gif"
									/>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【再現性】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【華氏、摂氏のいわれ】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										同一条件（測定方法、測定物体など）で繰り返し測定した場合の測定値のバラツキの程度をいいます。この値が小さいほど性能が優れています。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										華氏は、ファーレンハイトを中国語で「華倫海」、摂氏は、セルシウスの「攝爾修」（“攝”は“摂”の旧字体）、それぞれの頭文字をとった略号ですが、現在は、カ氏（F）またはセ氏（℃）と略称しています。
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【℃／Fの換算式】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【宇宙の温度は-270℃】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
									　　℃=5/9（F-32）です。
									　　<br />
										100Fの温度を℃に換算する場合、
										<br />
										℃=5/9（100-32）≒37.8
										<br />
										100Fは約37.8℃であることがわかります。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										1965年、アメリカのペンジャスとウィルソンは宇宙のあらゆる方向から来るマイクロ波（宇宙背景放射）をキャッチ。
										<br />
										その強度が、３Kであることを発見しました。
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【世界の最低気温は-89.2℃】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【人工衛星から、原発事故を発見】
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										1983年、南極で記録されました。一方、最高気温は1921年、イラクで58.8℃を記録しました。
										<br />
										（「気象年鑑」1986年版より）
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										「ソ連の原発事故を真っ先に写したのが、人工衛星だった」というのは有名な話です。
										<br />
										これは可視光線から赤外線までさまざまな光をとらえコンピュータ処理された画像でした。
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										【0.2℃の温度差をキャッチするハブのセンサー】
									</p>
								</li>
								<li className="temp10_title">
									<p className="temp01_font_title temp10_font_title">
										
									</p>
								</li>
							</ul>
							<ul className="temp10_ul">
								<li className="temp10_explanation">
									<p className="medium">
										ハブの鼻孔の脇についているピット器官は、赤外線をキャッチする優秀なセンサーになっています。
										<br />
										この特殊な感覚器官が、獲物を探知し捕獲するのに大いに役立っているのです。
									</p>
								</li>
								<li className="temp10_explanation">
									<p className="medium">
										<br />
										<br />
										<br />
									</p>
								</li>
							</ul>
						</div>
					</div>
					<PagingController
						current={10}
						next={"#"}
						prev={"/support/documents/about-temperature/temp09"}
					/>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
